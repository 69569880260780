import {Header} from "../../components/Header/Header";
// @ts-ignore
import Image from "../../assets/images/location/pngimg.com - gps_PNG47.png";
// @ts-ignore
import Image5 from "../../assets/images/homepage/Esstisch.jpg";
// @ts-ignore
import Image6 from "../../assets/images/homepage/Küche2.jpg";
// @ts-ignore
import Image7 from "../../assets/images/homepage/81C1C04B-532F-4CBB-A52D-4D49DDFCA6B3.jpeg";
// @ts-ignore
import Image1 from "../../assets/images/homepage/Flur.jpg";

export const Location = () => {

    return (
        <Header noSvg home>
            <div style={{ position: 'relative', top: '7.5rem' }}>
                <div className="container-fluid mt-4 p-5">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 text-center">
                            <p className="text__primary">Mönchengladbach</p>

                            <h3>möblierte Wohnung Mönchengladbach / Ferienwohnung Mönchengladbach</h3>

                            <div className="col-lg-10 col-md-12 text-center m-auto">
                                <p>
                                    Unsere Apartments befinden sich im Raum Mönchengladbach. Neben ruhigen Lagen bieten wir auch zentrale Lagen mit direkten Bus- und Bahnverbindungen an. Alle Einrichtungen des täglichen Bedarfs liegen in unmittelbarer nähe. Überwiegend befinden sich die Apartments im Stadtteil Odenkirchen. Senden Sie uns gerne eine Anfrage zu Ihrem Wunsch-Standort zu und wir prüfen die Verfügbarkeiten umgehend.
                                </p>

                                <a href="tel:+491704524890" className="btn btn-sm btn__primary text-light">Jetzt anrufen</a>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 text-center" style={{ position: 'relative', top: '3rem' }}>
                            <img
                                src={Image}
                                width={'90%'}
                                height="auto"
                            />
                        </div>
                    </div>
                </div>

                <div className="container-fluid bg__card text-center p-5">
                    <h3 className="text-light">Gerne zeigen wir Ihnen mehr Fotos zum passenden Apartment</h3>

                    <div className="row mt-5">
                        <div className="col-lg-3 col-md-6 col-sm-12 mt-2">
                            <img src={Image5} width={"100%"} height={'auto'} className="homepage__gallery__container__image--border" style={{ borderTopLeftRadius: '150px', borderBottomLeftRadius: '150px' }} />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 mt-2">
                            <img src={Image6} width={"100%"} height={'auto'} className="homepage__gallery__container__image--border"  />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 mt-2">
                            <img src={Image7} width={"100%"} height={'auto'} className="homepage__gallery__container__image--border"  />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 mt-2">
                            <img src={Image1} width={"100%"} height={'auto'} className="homepage__gallery__container__image--border" style={{ borderTopRightRadius: '150px', borderBottomRightRadius: '150px' }}  />
                        </div>
                    </div>
                </div>
            </div>
        </Header>
    )
}