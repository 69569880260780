import ReactDOM from "react-dom/client";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import { Homepage } from "./feature";
import {Apartments} from "./feature/apartments";
import {Location} from "./feature/location";
import {Contact} from "./feature/contact";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


const Routing = () => {
    return(
        <Router>
            <Routes>
                <Route element={<Homepage />} path="/" />
                <Route element={<Apartments />} path="/apartments" />
                <Route element={<Location />} path="/location" />
                <Route element={<Contact />} path="/contact" />
            </Routes>
        </Router>
    )
}

root.render(<Routing />);