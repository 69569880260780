import {Header} from "../../components/Header/Header";
// @ts-ignore
import Image3 from "../../assets/images/homepage/Wohn-Schlafzimmer.jpg";
// @ts-ignore
import Image1 from "../../assets/images/homepage/Flur.jpg";
// @ts-ignore
import Image2 from "../../assets/images/homepage/IMG_1534.jpg";
// @ts-ignore
import Image4 from "../../assets/images/luke-van-zyl-koH7IVuwRLw-unsplash.jpg";
import {Card} from "../index";


export const Apartments = () => {
    return(
        <Header appartments>
            <>
                <div className="bg__primary">
                    <div style={{ position: 'relative', bottom: '3rem' }}>
                        <h3 className="text-center text-light text-decoration-underline" style={{ letterSpacing: '3px' }}>Unsere Apartments</h3>

                        <div className="container-fluid row pb-5">
                            <div className="col-lg-4 col-md-12 text-center">
                                <Card image={Image3} title="Wohnzimmer" />
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <Card image={Image1} title="Flur" />
                            </div>
                            <div className="col-lg-4 col-md-12 text-center">
                                <Card image={Image2} title="Badezimmer" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-4 p-4">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 text-center">
                            <img
                                src={Image4}
                                width={'90%'}
                                height="auto"
                                className="homepage__gallery__container__image--revert__apartments"
                            />
                        </div>
                        <div className="col-lg-6 col-md-12 text-center">
                            <p className="text__primary">Ausstattung</p>

                            <h3>Unsere Apartments sind komplett ausgestattet und haben unter anderem:</h3>

                            <div className="col-lg-10 col-md-12 text-center m-auto">
                                <p>
                                    - 55 Zoll Samsung Smart-TV <br />
                                    - Schallschutztür + Rollläden <br />
                                    - neuwertige Möbel <br />
                                    - gute W-Lan Verbindung <br />
                                    - Handtücher und Bettdecken <br />
                                    - komplett ausgestattete Küche <br />
                                    - Waschmaschine <br />
                                    - ruhiges Haus mit guter Mieterstruktur <br />
                                    Und vieles mehr….
                                </p>

                                <a href="tel:+491704524890" className="btn btn-sm btn__primary text-light">Jetzt anrufen</a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </Header>
    )
}