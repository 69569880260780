import {Navbar} from "../Navbar";
// @ts-ignore
import Logo from "../../assets/images/logo.jpg";
import {Footer} from "../Footer";

export const Header = ({ children, appartments, noSvg, home, contact }: { contact?: any, children: any, appartments?: any, noSvg?: any, home?: any }) => {
    const getHeader = () => {
        if (home) {
            return "header__background--home";
        }

        if (appartments) {
            return "header__background--appartments";
        }

        if (contact) {
            return "header__background--contact";
        }

        return "header__background";
    }

    return (
        <>
            <Navbar />

            <div className={getHeader()}>
                <div className="header__title">
                    <div className="text-center">
                        <h4 className="text-light">Ferienwohnung Mönchengladbach</h4>
                        <img src={Logo} width={"125em"} height={'auto'} style={{ borderRadius: 15 }} className="mb-3" />
                        <p className="text-light">
                            Die perfekte Hotel Alternative! Ab einem Mindestaufenthalt von 7 Tagen bieten wir unsere gemütlichen Apartments für 1 – 5 Personen an. Rufen Sie uns gerne für mehr Informationen an.
                        </p>
                        <a href="mailto:mg-apartments@outlook.de" className="btn btn__primary btn-sm text-light">
                            Anfrage stellen
                        </a>
                    </div>
                </div>
            </div>

            <div className="header__content">
                {!noSvg && (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style={{ position: 'relative', top: '0.1rem' }}>
                        <path fill="#97ca8d" fill-opacity="1" d="M0,160L120,144C240,128,480,96,720,96C960,96,1200,128,1320,144L1440,160L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
                    </svg>
                )}
                {children}
            </div>

            <Footer />
        </>
    )
}