import {Header} from "../components/Header/Header";

// @ts-ignore
import Image1 from "../assets/images/homepage/Flur.jpg";
// @ts-ignore
import Image2 from "../assets/images/homepage/IMG_1534.jpg";
// @ts-ignore
import Image3 from "../assets/images/homepage/Wohn-Schlafzimmer.jpg";
// @ts-ignore
import Image4 from "../assets/images/homepage/IMG_1533.jpg";
// @ts-ignore
import Image5 from "../assets/images/homepage/Esstisch.jpg";
// @ts-ignore
import Image6 from "../assets/images/homepage/Küche2.jpg";
// @ts-ignore
import Image7 from "../assets/images/homepage/81C1C04B-532F-4CBB-A52D-4D49DDFCA6B3.jpeg";
// @ts-ignore
import Logo from "../assets/images/pastell neu - transparent.png";

export const Card = ({ image, title, text }: { image: any, title: string, text?: string | null }) => {
    return(
        <div className="ml-auto mr-auto text-center mt-2">
            <img
                src={image}
                width={'90%'}
                height="auto"
                className="homepage__gallery__container__image"
                style={{ position: 'relative', bottom: '0rem' }}
            />

            <div className="card card-body m-auto bg__card" style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0, border: '2px solid #fff', position: 'relative', bottom: '.1rem', width: '90%' }}>
                <h4 className="text-light" style={{ letterSpacing: '3px' }}>{title}</h4>
                <p className="text-light">{text ?? "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy"}</p>
            </div>
        </div>
    )
}

export const Homepage = () => {
   return (
       <Header home>
           <>
               <div className="bg__primary">
                   <div style={{ position: 'relative', bottom: '3rem' }}>
                       <h3 className="text-center text-light text-decoration-underline m-0" style={{ letterSpacing: '3px' }}>möblierte Wohnung Mönchengladbach</h3>
                       <p className="text-center text-light col-lg-9 col-md-12 m-auto mb-4">Die Preise richten sich an die Personenanzahl und Aufenthaltsdauer. Teilen Sie uns gerne die Information telefonisch oder per Mail mit und wir errechnen Ihnen den Preis in wenigen Minuten!</p>

                       <div className="container-fluid row pb-5">
                           <div className="col-lg-4 col-md-12 text-center">
                               <Card image={Image3} title="Wohnzimmer" text="Unsere Auswahl geht von 1-Raum Apartments bis hin zu 2- und 3-Raum Apartments. Jedes Wohnzimmer verfügt über ein 50 Zoll Samsung TV und sind" />
                           </div>
                           <div className="col-lg-4 col-md-12">
                               <Card image={Image1} title="Wohnkomfort" text="Die Apartments sind alle neuwertig, modern eingerichtet und haben Balkone – damit Sie sich wie zuhause fühlen!" />
                           </div>
                           <div className="col-lg-4 col-md-12 text-center">
                               <Card image={Image2} title="Badezimmer" text="Moderne sanierte Badezimmer, wahlweise mit ebenerdige Dusche, Badewanne oder beidem!" />
                           </div>
                       </div>
                   </div>
               </div>

               <div className="container-fluid mt-4 p-4">
                   <div className="row">
                       <div className="col-lg-6 col-md-12 text-center">
                           <div className="homepage__gallery__container__image--revert">
                               <img
                                   src={Logo}
                                   width={'75%'}
                                   height="auto"
                               />
                           </div>
                       </div>
                       <div className="col-lg-6 col-md-12 text-center">
                           <h3>Wohlfühl-Apartments seit 2016!</h3>

                           <div className="col-lg-10 col-md-12 text-center m-auto">
                               <p>
                                   Unsere Apartments sind die perfekte Alternative zum Hotel. Ihnen stehen großzügig aufgeteilte Apartments für die alleinige Nutzung zur Verfügung. In Kombination mit einem guten Preis-Leistungs-Verhältnis und einem ruhigen Haus werden Sie sich bei uns wohl fühlen!
                                   Fragen Sie die Verfügbarkeit direkt telefonisch an.
                               </p>

                               <a href="tel:+491704524890" className="btn btn-sm btn__primary text-light">Jetzt anrufen</a>
                           </div>
                       </div>
                   </div>
               </div>

               <div className="container-fluid bg__card text-center p-5 homepage__gallery__images mt-4">
                   <h3 className="text-light mt-5">Gerne zeigen wir Ihnen mehr Fotos zum passenden Apartment.</h3>

                   <div className="row mt-5">
                       <div className="col-lg-3 col-md-6 col-sm-12 mt-2">
                           <img src={Image5} width={"100%"} height={'auto'} className="homepage__gallery__container__image--border" style={{ borderTopLeftRadius: '150px', borderBottomLeftRadius: '150px' }} />
                       </div>
                       <div className="col-lg-3 col-md-6 col-sm-12 mt-2">
                           <img src={Image6} width={"100%"} height={'auto'} className="homepage__gallery__container__image--border"  />
                       </div>
                       <div className="col-lg-3 col-md-6 col-sm-12 mt-2">
                           <img src={Image7} width={"100%"} height={'auto'} className="homepage__gallery__container__image--border"  />
                       </div>
                       <div className="col-lg-3 col-md-6 col-sm-12 mt-2">
                           <img src={Image1} width={"100%"} height={'auto'} className="homepage__gallery__container__image--border" style={{ borderTopRightRadius: '150px', borderBottomRightRadius: '150px' }}  />
                       </div>
                   </div>
               </div>

               <div className="container-fluid homepage__gallery__container__contact">
                    <div
                        className="card card-body bg__card col-lg-6 col-md-10 m-auto homepage__gallery__container__image__border-radius"
                    >
                        <h4 className="text-light text-center mt-5">Kontaktieren sie uns</h4>

                        <p className="text-light text-center col-10 m-auto mt-5">
                            Rufen Sie uns gerne für Fragen zum Preis und zur Verfügbarkeit an. Teilen Sie uns bitte mit, welchen Zeitraum Sie buchen möchten. Unsere Apartments bieten wir ab einem Mindestaufenthalt von 7 Nächten an.
                        </p>

                        <a href="/contact" className="btn btn-sm btn__secondary text-light col-3 m-auto mt-5 mb-5">Kontakt</a>
                    </div>
               </div>
           </>
       </Header>
   )
}