
export const Footer = () => {
    return(
        <footer className="footer-main bg__card">
            <div className="container">
                <div className="row address-main">
                    <div className="col-lg-4 col-sm-12 col-xs-12">
                        <div className="address-box clearfix">
                            <div className="add-icon">
                                <i className="fa fa-users text-light fa-2x" style={{ position: 'relative', top: '.5rem' }}></i>
                            </div>
                            <div className="add-content">
                                <h5>Anschrift</h5>
                                <p>
                                    Burgfreiheit 27<br />
                                    41199 Mönchengladbach
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 col-xs-12">
                        <div className="address-box clearfix">
                            <div className="add-icon">
                                <i className="fa-solid fa-phone text-light fa-2x" style={{ position: 'relative', top: '.5rem' }}></i>
                            </div>
                            <div className="add-content">
                                <h5>Telefonnummer</h5>
                                <p>0170 452 4890</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 col-xs-12">
                        <div className="address-box clearfix">
                            <div className="add-icon">
                                <i className="fa fa-envelope text-light fa-2x" style={{ position: 'relative', top: '.5rem' }}></i>
                            </div>
                            <div className="add-content">
                                <h5>Email</h5>
                                <p> <a href="mailto:mg-apartments@outlook.de" style={{ textDecoration: 'none' }}>mg-apartments@outlook.de</a> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="bg__card text-center text-white">
                <div className="container p-4 pb-0">
                    <section className="mb-4">
                        <p>
                            Apartment Lori <br />
                            Tel: 0170 4524890
                        </p>
                    </section>
                </div>
                <div className="text-center p-3" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                    Lori Apartment © 2023 All Rights Reserved.
                </div>
            </footer>
        </footer>
    )
}