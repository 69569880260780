// @ts-ignore
import Logo from "../../assets/images/pastell neu - transparent.png";
import {useEffect} from "react";


export const Navbar = () => {

    useEffect(() => {
        const nav = document.querySelector(".nav");
        const navbarCollapser = document.querySelector(".nav__collapser");

        // @ts-ignore
        navbarCollapser!.onclick = () => {
            nav!.classList.toggle("nav--expanded");
        };
    })

    return(
        <nav className="nav">
            <img className="nav__collapser"
                 src="https://raw.githubusercontent.com/JamminCoder/grid_navbar/master/menu.svg" alt="Collapse" />
            <div className="nav__collapsable">
                <a href="/" className="text-dark p-2">
                    <i className="p-1 fa-regular fa-house-chimney"></i>
                    Hauptseite
                </a>
                <a href="/apartments" className="text-dark p-2">
                    <i className="p-1 fa-solid fa-house-chimney-user"></i>
                    Unsere Apartments
                </a>
                <a href="/location" className="text-dark p-2">
                    <i className="p-1 fa-solid fa-house-flag"></i>
                    Lage
                </a>
                <a href="/contact" className="btn btn__primary btn-sm">Kontakt</a>
                <a href="tel:+491704524890" className="text-dark p-2">
                    +49 170 4524890
                </a>
            </div>
        </nav>
    )
}